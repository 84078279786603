import React, { useState } from 'react';
import { withRouter } from 'react-router'
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { connect } from 'react-redux'
import Auth from '../../../containers/Auth/Auth'
import Sidebar from '../Sidebar/Sidebar'
import './Toolbar.css'

const Toolbar = (props) => {
    const [navExpanded, setNavExpanded] = useState(false)
    const [modalShow, setModalShow] = useState(false)

    const onSetNavExpanded = (expanded) => {
        console.log("expanded === ", expanded);
        setNavExpanded(expanded);
    }

    const closeNav = () => {
        console.log("closeNav === called")
        setNavExpanded(false);
    }

    const redirectTo = (link) => {
        props.history.push('/' + link);
    }

    let modal = null;
    if (!props.isAuthenticated) {
        modal = (<Auth show={modalShow} onHide={() => setModalShow(false)} />)
    }

    return (
        <React.Fragment>
            {modal}
            <header className="desktopNav" >
                <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
                    <Link to="/"><Navbar.Brand >WholesaleCost.in</Navbar.Brand></Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto" style={{ width: '80%' }}>
                            <Form inline style={{ width: '70%' }} >
                                <FormControl type="text" placeholder="Search" className="mr-sm-2" style={{ width: '70%' }} />
                                <Button variant="outline-light" style={{ width: '20%' }} >Search</Button>
                            </Form>
                            <h5 className="mt-2 text-white" >Call - 09981277722 </h5>
                            {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
                        </Nav>
                        <Nav>
                            <NavDropdown title="My Account" id="collasible-nav-dropdown" style={{ color: "#fff" }} >
                                {!props.isAuthenticated ? <NavDropdown.Item onClick={() => setModalShow(true)}>Login</NavDropdown.Item>
                                    : (
                                        <React.Fragment>
                                            <NavDropdown.Item style={{
                                                color: "blue",
                                                fontFamily: "cursive",
                                                fontSize: "16px",
                                                fontWeight: "600"
                                            }}>{props?.user?.name?.toUpperCase()}
                                                <br /><font style={{
                                                    fontSize: 12,
                                                }}>{props?.user?.phone}</font></NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('new-order')} >New Order</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('order')} >Order</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('my-info')} >My Info</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('add-user')} >Add User</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('user-list')} >My User</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('balance')} >Balance</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('balance-transfer')} >Transfer Balance</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('add-balance')} >Add Balance</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('logout')} >Logout</NavDropdown.Item>
                                        </React.Fragment>
                                    )}
                                {/* <NavDropdown.Divider /> */}
                            </NavDropdown>
                            {props.isAuthenticated ? (<Nav.Link eventKey={2} style={{ color: "#fff" }} >
                                Balance
                                {/* Cart <FontAwesomeIcon icon={faShoppingCart} /> */}
                                <Badge variant="danger" className="ml-2" >{props.balance}</Badge>
                            </Nav.Link>) : null}

                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
            <header className="mobileNav">
                <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" onToggle={onSetNavExpanded} expanded={navExpanded}>
                    <div className="topBar">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav >
                                <Sidebar closeNav={closeNav} />
                            </Nav>
                        </Navbar.Collapse>
                        <Link to="/"><Navbar.Brand >WholesaleCost.in</Navbar.Brand></Link>
                        <NavDropdown title="" id="collasible-nav-dropdown" style={{ color: "#fff" }} >
                            {!props.isAuthenticated ? <NavDropdown.Item onClick={() => setModalShow(true)}>Login</NavDropdown.Item>
                                : (<React.Fragment>
                                    <NavDropdown.Item onClick={() => redirectTo('new-order')} >New Order</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => redirectTo('order')} >Order</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => redirectTo('my-info')} >My Info</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => redirectTo('add-user')} >Add User</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => redirectTo('user-list')} >My User</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => redirectTo('balance')} >Balance</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => redirectTo('balance-transfer')} >Transfer Balance</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => redirectTo('add-balance')} >Add Balance</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => redirectTo('logout')} >Logout</NavDropdown.Item>
                                </React.Fragment>)}
                            {/* <NavDropdown.Divider /> */}
                        </NavDropdown>
                        {props.isAuthenticated ? (<Nav.Link eventKey={2} style={{ color: "#fff" }} >
                            {/* <FontAwesomeIcon className="shoppingCart" icon={faShoppingCart} /> */}
                            <Badge variant="danger" className="ml-2" >{props.balance}</Badge>
                        </Nav.Link>) : null}
                    </div>
                    <div className="searchBox">
                        <Form>
                            <FormControl type="text" placeholder="Search" />
                            <FontAwesomeIcon className="searchIcon" icon={faSearch} />
                        </Form>
                    </div>

                </Navbar>
            </header>
        </React.Fragment>
    )
}


const mapStateToProps = state => {
    return {
        balance: state.auth.balance,
        user: state.auth.user,
        isAuthenticated: state.auth.token !== null
    }
}

export default connect(mapStateToProps, null)(withRouter(Toolbar));