import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Formik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2'

import * as storeActions from "../../../store/actions"
import Spinner from '../../../components/UI/Spinner/Spinner'
import axios from '../../../axios/axios';

class Login extends Component {

  render() {

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = yup.object().shape({
      name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Name is not valid').required(),
      //email: yup.string().email('Invalid email').required('Required'),
      phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
      password: yup.string().required(),
    });

    let loginForm = (
      <Formik
        validationSchema={schema}
        onSubmit={(values, actions) => {
          let store = this.props.location.pathname ? this.props.location.pathname.split('/')[2] : null
          // this.props.onAuth(values.phone, values.password, values.name, store);
          actions.setSubmitting(false);
          axios.post('/send-otp', { phone: values.phone, name: values.name }).then(response => {
            console.log('Signup=== ', response.data)
            if (response.data.success) {
              this.props.onHide()
              Swal.fire({
                title: "Submit your OTP",
                input: "text",
                inputAttributes: {
                  autocapitalize: "off"
                },
                showCancelButton: true,
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                preConfirm: async (login) => {
                  console.log("login ", login)
                  if (!login) {
                    Swal.showValidationMessage(`
                      Otp is required
                    `);

                  } else {
                    await this.props.onAuth(values.phone, values.password, values.name, store, login);
                  }
                  return login;
                },
                // allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                if (result.isConfirmed) {
                  console.log("result == ", result)
                  // Swal.fire({
                  //   icon: "success",
                  //   title: "Signup Success",
                  //   // html: props.error,
                  // });
                  // this.props.onAuth(values.phone, values.password, values.name, store, result.value);

                }
              });
            }
            // if (response.data.payment_request.longurl) {
            //     window.location = response.data.payment_request.longurl;
            // }
          }).catch(error => {
            console.log('Signup error', error.response?.data?.message);
            console.log('Signup error', error.message);
            let errorMessage = "";
            if (error.response?.data?.message?.phone || error.response?.data?.message?.name) {
              errorMessage = '<div >';
              for (let key in error.response?.data?.message) {
                errorMessage += `<p >${key} : ${error.response?.data?.message[key]}</p>`;
              }
              errorMessage += '</div>';
            } else {
              errorMessage = error.message
            }

            Swal.fire({
              icon: "error",
              title: "Signup error",
              html: errorMessage,
            });

          })
        }}
        initialValues={{
          phone: '',
          password: '',
          name: ''
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <React.Fragment>
            <Card.Title>User Login</Card.Title>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationFormikName">
                  <Form.Label>Name</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      aria-describedby="inputGroupPrepend"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationFormikUsername">
                  <Form.Label>Phone Number</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      aria-describedby="inputGroupPrepend"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationFormikUsername">
                  <Form.Label>Password</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      aria-describedby="inputGroupPrepend"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Form.Check
                  required
                  name="terms"
                  label="Agree to terms and conditions"
                  onChange={handleChange}
                  isInvalid={!!errors.terms}
                  feedback={errors.terms}
                  id="validationFormik0"
                />
              </Form.Group>
              <Button type="submit">Submit form</Button>
              <Button variant="secondary" size="sm" onClick={() => this.props.formSwipeHandler(true)} >
                Switch to Login
              </Button>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    );


    // console.log(this.props.loading )

    if (this.props.loading === 'true') {
      loginForm = (<Spinner />)
    }

    return (
      <React.Fragment>
        {loginForm}
      </React.Fragment>
    );
  }

}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading.toString(),
    error: state.auth.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (phone, password, name, store, otp) => dispatch(storeActions.auth(phone, password, name, store, otp)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));