import * as actionTypes from './actionsTypes'
import axios from '../../axios/axios';


export const setCategories = (categories)=>{
    return {
        type: actionTypes.SET_CATEGORIES,
        categories:categories
    }
}


export const fetchCategoriesFailed = (categories)=>{
    return {
        type: actionTypes.FETCH_CATEGORIES_FAILED,
    }
}

export const initCategories = ()=>{
    return dispatch => {
        axios.get('/categories').then(response=>{
            dispatch(setCategories(response.data.data))
        }).catch(error=>{
            dispatch(fetchCategoriesFailed())
        })
    }
}
