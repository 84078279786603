import React, { Component } from 'react'
import { withRouter } from 'react-router'
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { connect } from 'react-redux'
import * as actions from '../../../store/actions/';

class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showResellerPrice: true,
            resellerPrice: ''
        }
    }

    handleChange(event) {
        this.setState({ ...this.state, resellerPrice: event.target.value });
    }

    handleShowHide(val) {
        this.setState({ ...this.state, showResellerPrice: val === "hide" ? false : true });
    }

    byNow(pId, image, name, price, rPrice = 0) {
        const isStore = this.props.isStore;
        const storeUser = this.props.storeUser;
        console.log("isStore === ", isStore)
        console.log("storeUser === ", storeUser)
        this.props.history.push({
            pathname: '/bynow',
            state: {
                pId: pId,
                image: image,
                name: name,
                price: isStore ? rPrice : price,
                isStore: isStore,
                storeUser: storeUser
            }
        });
    }

    render() {
        let productJsxData = null;
        if (this.props.product) {
            let product = this.props.product;
            productJsxData = (
                <Card>
                    <div style={{ height: 200, textAlign: 'center', alignContent: "center" }}>
                        <img style={{
                            // keep this as it is
                            overflow: 'auto',
                            height: "100%",
                            maxWidth: "100%"
                        }}
                            src={process.env.REACT_APP_HOST + '/storage/' + product.image}
                            onClick={() => this.props.onMoveToProductDetail(product.slug)}
                            alt=""
                        />
                    </div>

                    {/* <Card.Img
                        onClick={() => this.props.onMoveToProductDetail(product.slug)}
                        variant="top"
                        src={process.env.REACT_APP_HOST + '/storage/' + product.image}
                    /> */}
                    <Card.Body>
                        <h5 onClick={() => this.props.onMoveToProductDetail(product.slug)} style={{ fontSize: "18px !important" }}>{product.name}</h5>

                        {product?.prices ? (
                            <>
                                <Card.Title> Your buying price {' '}<Badge variant="success" className="float-right" >4.5 <FontAwesomeIcon icon={faStar} /> </Badge></Card.Title>
                                <Form method="post" >

                                    <Form.Row className="align-items-center">
                                        <Col xs sm md lg xl xxl="8" >
                                            <InputGroup className="mb-2">
                                                <FormControl id={"inlineFormInputGroupCellPrice" + product.id} placeholder="" value={product?.rprices?.price ? product?.rprices?.price : product.sell_price} disabled />
                                            </InputGroup>
                                        </Col>
                                        <Col xs sm md lg xl xxl="4" >
                                            <Button type="button" onClick={() => this.byNow(
                                                product.id,
                                                product.image,
                                                product.name,
                                                product?.rprices?.price ? product?.rprices?.price : product.sell_price,
                                                product?.prices?.price,
                                            )} className="mb-2 float-right">
                                                By Now
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                    <Card.Title> Your saleing price </Card.Title>
                                    <Form.Row className="align-items-center">
                                        <Col xs sm md lg xl xxl="6" >
                                            <InputGroup className="mb-2">
                                                {/* <InputGroup.Prepend>
                                                    <InputGroup.Text onClick={this.removeQuqntity} ><FontAwesomeIcon icon={faMinus} /></InputGroup.Text>
                                                </InputGroup.Prepend> */}
                                                <FormControl
                                                    style={{ display: this.state.showResellerPrice ? "block" : "none" }}
                                                    id={"inlineFormInputGroupResellerPriceReadOnly" + product.id}
                                                    onClick={() => this.handleShowHide("hide")}
                                                    placeholder=""
                                                    value={product?.prices?.price}
                                                    readOnly
                                                />
                                                <FormControl style={{ display: this.state.showResellerPrice ? "none" : "block" }}
                                                    id={"inlineFormInputGroupResellerPrice" + product.id} onChange={this.handleChange.bind(this)} placeholder=""
                                                    value={this.state.resellerPrice}
                                                />
                                                {/* <InputGroup.Append>
                                                    <InputGroup.Text onClick={this.addQuqntity} ><FontAwesomeIcon icon={faPlus} /></InputGroup.Text>
                                                </InputGroup.Append> */}
                                            </InputGroup>
                                        </Col>
                                        <Col xs sm md lg xl xxl="6" >
                                            <Button type="button"
                                                style={{
                                                    fontSize: 13,
                                                    fontWeight: 600
                                                }}
                                                onClick={() => { this.props.onAddUpdatePrice(this.state.resellerPrice, product?.prices?.id, this.props.token, product.id); this.handleShowHide("show"); }} className="mb-2 float-right">
                                                Update Price
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </>
                        )
                            : null}
                    </Card.Body>
                </Card>
            )
        }

        return (
            <React.Fragment>
                {productJsxData}
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    // console.log('state.product.similerData', state.product.similerData);
    return {
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAddToCart: (cart) => dispatch(actions.addToCart(cart)),
        onAddUpdatePrice: (resellerPrice, id, token, productId) => dispatch(actions.addUpdatePrice(resellerPrice, id, token, productId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductList));