import * as actionTypes from './actionsTypes'
import axios from '../../axios/axiosAuth';


export const setOrders = (orders)=>{
    return {
        type: actionTypes.SET_ORDERS,
        orders:orders
    }
}


export const fetchOrdersFailed = ()=>{
    return {
        type: actionTypes.FETCH_ORDERS_FAILED,
    }
}

export const initOrders = (token)=>{
    return dispatch => {
        axios.post('/orders',{},{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }).then(response=>{
            dispatch(setOrders(response.data.data))
        }).catch(error=>{
            dispatch(fetchOrdersFailed())
        })
    }
}