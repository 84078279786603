import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    product: null,
    similerData: [],
    products: [],
    totalPage: null,
    loading: false,
    error: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REQUEST_START:
            return {
                ...state,
                loading: true,
                error: false
            };
        case actionTypes.INIT_PRODUCTS:
            return {
                ...state,
                products: action.products,
                totalPage: action.totalPage,
                loading: false,
                error: false
            };
        case actionTypes.SET_PRODUCTS:
            return {
                ...state,
                products: state.products.concat(action.products),
                totalPage: action.totalPage,
                loading: false,
                error: false
            };
        case actionTypes.UPDATE_PRODUCTS_PRICE:
            let tempProduct = state.products;
            for (let key in tempProduct) {
                if (tempProduct[key].id === action.id) {
                    tempProduct[key].prices.price = action.price
                }
            }
            return {
                ...state,
                products: JSON.parse(JSON.stringify(tempProduct)),
                totalPage: state.totalPage,
                loading: false,
                error: false
            };
        case actionTypes.PRODUCT_BY_ID:
            return {
                ...state,
                product: JSON.parse(JSON.stringify(action.product)),
                similerData: action.similerData,
                loading: false,
                error: false
            };
        case actionTypes.FETCH_PRODUCTS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
};

export default reducer;