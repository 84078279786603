import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router'
import ListGroup from 'react-bootstrap/ListGroup';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/';
import Aux from '../../../hoc/Auxs'

class Sidebar extends Component {

    componentDidMount() {
        this.props.onInitCategories();
    }

    redirectTo(link) {
        // console.log("link ==", link)
        if (this.props.closeNav)
            this.props.closeNav()
        this.props.history.push('/' + link);
    }

    sidebars = [
        { name: "New Order", path: "new-order" },
        { name: "Order", path: "order" },
        { name: "My Info", path: "my-info" },
        { name: "Add User", path: "add-user" },
        { name: "My User", path: "user-list" },
        { name: "Balance", path: "balance" },
        { name: "Transfer Balance", path: "balance-transfer" },
        { name: "Add Balance", path: "add-balance" },
        { name: "Logout", path: "logout" }
    ]

    render() {
        // let catList = null;
        // if (this.props.cats) {
        //     catList = (
        //         <Aux>
        //             {this.props.cats.map(cat => {
        //                 return (<Link to="#" onClick={() => this.redirectTo(`category/${cat.slug}`)} key={cat.id} >
        //                     <ListGroup.Item  ><strong>{cat.name}</strong></ListGroup.Item>
        //                 </Link>)
        //             })}
        //         </Aux>
        //     )
        // }
        let sidebarList = (
            <Aux>
                {this.sidebars.map(sidebar => {
                    return (<Link to="#" onClick={() => this.redirectTo(`${sidebar.path}`)} key={sidebar.path} >
                        <ListGroup.Item  ><strong>{sidebar.name}</strong></ListGroup.Item>
                    </Link>)
                })}
            </Aux>
        )
        return (
            <ListGroup>
                {sidebarList}
            </ListGroup>
        )
    }
}


const mapStateToProps = state => {
    return {
        cats: state.category.categories
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitCategories: () => dispatch(actions.initCategories()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));