import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility'
const initialState = {
    token: null,
    user: null,
    balance: 0,
    authError: null,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return updateObject(state, { authError: null, loading: true });
        case actionTypes.AUTH_SUCCESS:
            return updateObject(state, {
                token: action.token,
                user: action.user,
                balance: action.balance,
                authError: null,
                loading: false,
            });
        case actionTypes.DEDUCT_BELENCE:
            return updateObject(state, { balance: action.balance });
        case actionTypes.AUTH_FAIL:
            // console.log("action.authError === pp ", action.authError)
            return {
                ...state,
                authError: action.authError,
                loading: false
            }
        case actionTypes.AUTH_LOGOUT:
            return updateObject(state, { token: null, user: null, balance: 0 });
        default:
            return state;
    }
};

export default reducer;