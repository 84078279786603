import React, { Component } from 'react'
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProductList from '../Products/ProductList/ProductList'

import './Home.css'
import Spinner from '../../components/UI/Spinner/Spinner'
import * as actions from '../../store/actions/';

//import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import InfiniteScroll from "react-infinite-scroll-component";


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initData: {
                currentPage: 1,
                dataLength: 8,
                hasMore: true
            }
        }
    }

    componentDidMount() {
        this.props.onInitProducts(this.setParams(), this.props.token);
    }
    componentDidUpdate(prevProps) {
        if (!prevProps.token && this.props.token) {
            this.props.onInitProducts(this.setParams(), this.props.token);

        }
    }

    setParams = () => {
        return {
            params: {
                page: this.state.initData.currentPage,
                size: this.state.initData.dataLength,
            }
        }
    }

    fetchMoreData() {
        // console.log('before set state', this.state)
        this.setState({
            ...this.state,
            initData: {
                ...this.state.initData,
                currentPage: this.state.initData.currentPage + 1,
                hasMore: (this.props.totalProductPage < (this.state.initData.currentPage + 1)) ? false : true,
            }
        })
        // console.log('after set state', this.state)
        this.props.onInitProducts(this.setParams(), this.props.token);
    }

    moveToProductDetail(slug) {
        this.props.history.push({
            pathname: '/' + slug,
            state: {
                isStore: false
            }
        })
    }

    render() {
        let productJsxData = null;
        if (this.props.products) {
            productJsxData = (<Row>
                {this.props.products.map(product => {
                    return (<Col lg={3} md={3} className="mt-2 mb-2" key={product.id}>
                        <ProductList
                            isStore={false}
                            onMoveToProductDetail={this.moveToProductDetail.bind(this)}
                            product={product}></ProductList>
                    </Col>)
                })}
            </Row>)
        }

        if (this.props.loading === 'true') {
            productJsxData = (<Spinner />)
        }


        return (
            <React.Fragment>
                <InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={this.state.initData.currentPage * this.state.initData.dataLength}
                    next={this.fetchMoreData.bind(this)}
                    hasMore={this.state.initData.hasMore}
                    loader={''}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            <b>Yay! All product loaded</b>
                        </p>
                    }
                >
                    {productJsxData}
                </InfiniteScroll>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        products: state.product.products,
        totalProductPage: state.product.totalPage,
        loading: state.product.loading.toString()

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitProducts: (initData, token) => dispatch(actions.initProducts(initData, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
//export default connect(mapStateToProps,mapDispatchToProps)(withErrorHandler(Home,axios));