import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Swal from 'sweetalert2'


import Login from './Login/Login'
import Signup from './Signup/Signup'

const Modals = (props) => {

  const [loginForm, setLoginForm] = useState(true);
  // const [show, setShow] = useState(false);

  const onFormSwipeHandler = (type) => {
    setLoginForm(type)
  }

  // const onModalCloseHandler = () => {
  //   console.log("called=== onModalCloseHandler")
  //   this.setState({ show: false });
  // }

  useEffect(() => {
    console.log("this.props.error -- 00 prevProps", props.authError)
    if (props.authError) {
      console.log("props.error -- 00", props.authError)
      Swal.fire({
        icon: "error",
        title: "Auth error",
        // html: props.authError,
      });
    }
  }, [props.authError])

  useEffect(() => {
    console.log("props.token", props.token)
    if (props.token) {
      Swal.fire({
        icon: "success",
        title: "Success",
        // html: props.error,
      });
    }
  }, [props.token])

  let authForm = null;
  if (loginForm) {
    authForm = (<Login formSwipeHandler={(type) => onFormSwipeHandler(type)} onHide={props.onHide} />);
  } else {
    authForm = (<Signup formSwipeHandler={(type) => onFormSwipeHandler(type)} onHide={props.onHide} />);
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" onClick={props.onHide}>
            Using Grid in Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <Card className="text-center" style={{ height: '100%' }} >
                  <Card.Body>
                    {authForm}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );

}
const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    token: state.auth.token,
    // loading: state.auth.loading,
  }
}


export default connect(mapStateToProps, null)(Modals);