import * as actionTypes from './actionsTypes'

export const addToCart = (cart)=>{
    return {
        type: actionTypes.ADD_TO_CART,
        cart:cart
    }
}


export const removeToCart = (pId)=>{
    return {
        type: actionTypes.REMOVE_TO_CART,
        pId:pId
    }
}

export const addQuantityToCart = (pId)=>{
    return {
        type: actionTypes.ADD_QUANTITY_TO_CART,
        pId:pId
    }
}

export const removeQuantityToCart = (pId)=>{
    return {
        type: actionTypes.REMOVE_QUANTITY_TO_CART,
        pId:pId
    }
}

export const emptyCart = ()=>{
    return {
        type: actionTypes.EMPTY_CART,
    }
}

export const cartReload = () =>{
    return dispatch =>{
        const carts = JSON.parse(localStorage.getItem('carts'))
        if(carts){
            dispatch(addToCart(carts))
        }
    }
}




