import * as actionTypes from './actionsTypes'
import axios from '../../axios/axios';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    }
}
export const authSuccess = (token, user, balance) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        user: user,
        balance: balance
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        authError: error
    }
}

export const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('balance')
    return {
        type: actionTypes.AUTH_LOGOUT,
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token')
        const user = JSON.parse(localStorage.getItem('user'))
        const balance = JSON.parse(localStorage.getItem('balance'))
        if (!token) {
            dispatch(logout())
        } else {
            dispatch(authSuccess(token, user, balance))
        }
    }
}

export const deductBalance = (total_bilance, price) => {
    let balance = total_bilance - price;
    localStorage.setItem('balance', balance)
    return {
        type: actionTypes.DEDUCT_BELENCE,
        balance: balance
    }
}

export const auth = (phone, password, name, store = "", otp = "") => {
    return dispatch => {
        dispatch(authStart());
        if (name) {
            axios.post('/register', { phone: phone, password: password, name: name, otp: otp, store: store }).then(response => {
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                let balance = response.data?.balance?.final_amount ? response.data?.balance?.final_amount : 0
                localStorage.setItem('balance', balance)
                dispatch(authSuccess(response.data.token, response.data.user, balance))
            }).catch(error => {
                dispatch(authFail(error.response?.data?.message))
            })

        } else {
            axios.post('/login', { phone: phone, password: password }).then(response => {
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                let balance = response.data?.balance?.final_amount ? response.data?.balance?.final_amount : 0
                localStorage.setItem('balance', balance)
                dispatch(authSuccess(response.data.token, response.data.user, balance))
            }).catch(error => {
                dispatch(authFail(error.response?.data?.message))
            })
        }

    }
}






