export const REQUEST_START = 'REQUEST_START';
export const LIST_CATEGORIES = 'LIST_CATEGORIES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';
export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const PRODUCT_BY_ID = 'PRODUCT_BY_ID';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_TO_CART = 'REMOVE_TO_CART';
export const ADD_QUANTITY_TO_CART = 'ADD_QUANTITY_TO_CART';
export const REMOVE_QUANTITY_TO_CART = 'REMOVE_QUANTITY_TO_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const DEDUCT_BELENCE = 'DEDUCT_BELENCE';
export const INIT_PRODUCTS = 'INIT_PRODUCTS';
export const UPDATE_PRODUCTS_PRICE = 'UPDATE_PRODUCTS_PRICE';
export const SET_ORDERS = 'SET_ORDERS';
export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED';
