import * as actionTypes from '../actions/actionsTypes';
import axios from '../../axios/axios';

export const setProducts = (products, totalPage) => {
    return {
        type: actionTypes.SET_PRODUCTS,
        products: products,
        totalPage: totalPage,
    }
}


export const requestStart = () => {
    return {
        type: actionTypes.REQUEST_START,
    }
}

export const initProductsState = (products, totalPage) => {
    return {
        type: actionTypes.INIT_PRODUCTS,
        products: products,
        totalPage: totalPage
    }
}

export const updateproductPrice = (price, id) => {
    return {
        type: actionTypes.UPDATE_PRODUCTS_PRICE,
        price: price,
        id: id

    }
}


export const initProductById = (product, similerData) => {
    return {
        type: actionTypes.PRODUCT_BY_ID,
        product: product,
        similerData: similerData
    }
}

export const fetchProductsFailed = () => {
    return {
        type: actionTypes.FETCH_PRODUCTS_FAILED,
    }
}

export const initProducts = (initData, token) => {
    return dispatch => {
        dispatch(requestStart());
        let apiConfig = axios.get('/products', initData)
        if (token) {
            initData.headers = {
                'Authorization': `Bearer ${token}`
            }
            apiConfig = axios.get('/products-auth', initData)
        }

        apiConfig.then(response => {
            if (initData.params.page === 1) {
                //console.log('first line called 1');
                dispatch(initProductsState(response.data.data, response.data.totalPage))
            } else {
                //console.log('second line called 2');
                dispatch(setProducts(response.data.data, response.data.totalPage))
            }

        }).catch(error => {
            dispatch(fetchProductsFailed())
        })
    }
}

export const productById = (id, token) => {
    return dispatch => {
        let apiConfig = axios.get('/product', { params: { id: id } })
        if (token) {
            apiConfig = axios.get('/product-auth', {
                params: { id: id }, headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        }
        apiConfig.then(response => {
            dispatch(initProductById(response.data.data, response.data.similerData));
        }).catch(error => {
            dispatch(fetchProductsFailed())
        })
    }
}

export const addUpdatePrice = (resselerPrice, priceId, token, productId) => {
    return dispatch => {
        axios.post('/product-price-update', { price: resselerPrice, id: priceId }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch(updateproductPrice(resselerPrice, productId));
        }).catch(error => {
            dispatch(fetchProductsFailed())
        })
    }
}